const routes = [
  {
    index: true,
    label: "OpenPV",
    path: "/",
  },
  {
    labelKey: "about.title",
    label: "about",
    path: "/about",
  },
]

export default routes
